import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import capitalize from 'lodash/capitalize';
import { useDebouncedCallback } from 'use-debounce';
import isEqual from 'lodash/isEqual';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SlotInfo } from 'react-big-calendar';
import { usePrevious } from 'react-use';
import fastq from 'fastq';
import { CAB_PANEL_WIDTH } from '@CabComponents/CabPanel';
import {
  actions, MeetingSlot, RootState, ThunkDispatchType, DisplayCalendarsDict, FormErrors, EventRequestInfo, 
  MeetingHoldEventErrorResolution, CalDateRange, Meeting,
  Calendar, RecurringTimes, InvalidParticipantVotes, Leader, MeetingFilter, MeetingUpdate, Grant, 
  MeetingHoldEventError, NewMeetingUpdate, PrivateExternalParticipantCreate, MeetingStatus,
} from '../store';
import classes from './Schedule.module.css';
import { useMountEffect } from '../utils/hooks';
import { PAGE_URL, PROVIDER, PROVIDER_BY_ID, PROVIDER_BY_NAME } from '../constants';
import { 
  getCalendarsDisplayed, leaderHasCalendars, colorDisplayMeetingSlots, clearSelectedSlots, getLocalTimeZone,
  getInvalidParticipantVotes, TimeZone, allTimeZones, getSlotCreateResult, getSlotEditResult, getSlotsDeleteResult,
  getTimezoneFromMeeting, getMinSlotId, ExcludedSlotInfo, calendarToParticipant,
  NY_TZ,
} from '../utils/scheduleUtils';
import MeetingSettingsContainer from '../components/Schedule/MeetingSettings';
import CabinetPage from '../components/Common/CabinetPage';
import { checkForCalendarGrants, getGrants } from '../utils/authUtils';
import { CabinetModal } from '../components/Common/CabinetModal';
import MeetingErrors from '../components/Schedule/MeetingErrors';
import { ScheduleHeaderContainer } from '../components/Schedule/ScheduleHeader';
import CalendarSchedulerContainer from '../components/Schedule/CalendarScheduler';
import ScheduleShareModalContainer from '../components/Schedule/ScheduleShareModal';
import colors from '../colors';
import DuplicateMeetingModal, { 
  DuplicateMeetingSubmitProps 
} from '../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import DeleteMeetingModal from '../components/Schedule/DeleteMeetingModal';
import { getLeadersForScheduling } from '../utils/leaderUtils';
import ReusableMeetingModal from '../components/Schedule/ReusableMeetingModal';
import AdditionalCalendars from '../components/Schedule/AdditionalCalendarsModal';
import { selectEvents, selectUserRecurringMeetingSlots } from '../store/schedule/selectors';
import { useLocation, useParams } from 'react-router';
import { clearNavigationState, router } from '../router';
import CabSpinner from '@CabComponents/CabSpinner';
import { difference } from 'lodash-es';


type SlotState = {createdSlots: MeetingSlot[], deletedSlots: MeetingSlot[], updatedSlots: MeetingSlot[]};
type SlotOpTaskData = MeetingSlot|MeetingSlot[]|MeetingSlot['id']|MeetingSlot['id'][];
type SlotOpTask<T extends SlotOpTaskData> = { slot: T, operationFn: (slot: T) => Promise<void> };

const calendarSchedulerSx = { padding: 2, paddingTop: 0 };

const slotOperationQueue = fastq.promise(
  <T extends SlotOpTaskData>(task: SlotOpTask<T>) => task.operationFn(task.slot),
  1,
);

type CalendarWithAccess = Omit<Calendar, 'calendar_access_id'> & { calendar_access_id: number };

type RouteParams = { meetingId?: string;  provider?: string; };


const Schedule = (): ReactElement => {
  const leaders = useSelector((state: RootState) => state.leaders);
  const user = useSelector((state: RootState) => state.auth.user);
  const auth = useSelector((state: RootState) => state.auth);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const associationsLoaded = useSelector((state: RootState) => state.schedule.associationsLoaded);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const meetingSlots = useSelector((state: RootState) => state.schedule.meetingSlots);
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const newMeeting = useSelector((state: RootState) => state.schedule.newMeeting);
  const scheduleErrors = useSelector((state: RootState) => state.schedule.scheduleErrors);
  const slotsLoaded = useSelector((state: RootState) => state.schedule.slotsLoaded);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const calendarErrors = useSelector((state: RootState) => state.schedule.calendarErrors);
  const events = useSelector((state: RootState) => selectEvents(state));

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const fetchZoomSettings = useCallback(() => dispatch(actions.schedule.fetchZoomSettings()), [dispatch]);
  const fetchRemoteCalendars = useCallback(() => dispatch(actions.schedule.fetchRemoteCalendars()), [dispatch]);
  const fetchCalendars = useCallback(() => dispatch(actions.schedule.fetchCalendars()), [dispatch]);
  const fetchEvents = useCallback((
    googleCalendarIds: string[] | undefined, microsoftCalendarIds: string[] | undefined, startDate: string,
    endDate: string, forTimezone: string, clearCache?: boolean, frequencyMs?: number | undefined
  ) => dispatch(actions.schedule.fetchEvents({
    googleCalendarIds, microsoftCalendarIds, startDate,
    endDate, clearCache, frequencyMs, forTimezone
  })), [dispatch]);
  const startPollEvents = useCallback((
    googleCalendarIds: string[] | undefined, microsoftCalendarIds: string[] | undefined,
    startDate: string, endDate: string, forTimezone: string
  ) => dispatch(actions.schedule.startPollEvents(
    googleCalendarIds, microsoftCalendarIds, startDate, endDate, forTimezone
  )), [dispatch]);
  const fetchMeetingSlots = useCallback((unscheduled: boolean) => 
    dispatch(actions.schedule.fetchMeetingSlots(unscheduled)), [dispatch]);
  const fetchSlotsForMeeting = useCallback((meetingId: string) => 
    dispatch(actions.schedule.fetchSlotsForMeeting(meetingId)), [dispatch]);
  const fetchSchedulingPreferences = useCallback(() => 
    dispatch(actions.schedule.fetchSchedulingPreferences()), [dispatch]);
  const startPollingMeetings = useCallback((query: Partial<MeetingFilter>) => 
    dispatch(actions.schedule.startPollingMeetings(query)), [dispatch]);
  const fetchMeeting = useCallback((meetingId: string) => 
    dispatch(actions.schedule.fetchMeeting(meetingId)), [dispatch]);
  const updateNewMeeting = useCallback((meeting: NewMeetingUpdate | null) => 
    dispatch(actions.schedule.updateNewMeeting(meeting)), [dispatch]);
  const deleteNewMeeting = useCallback(() => dispatch(actions.schedule.deleteNewMeeting()), [dispatch]);
  const updateMeeting = useCallback((
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch(actions.schedule.updateMeeting(meeting, timeSlots, files)), [dispatch]);
  
  const updateTimeSlot = useCallback((timeSlot: MeetingSlot) =>
    dispatch(actions.schedule.updateTimeSlot(timeSlot)), [dispatch]);
  const deleteTimeSlot = useCallback((slot: MeetingSlot) => 
    dispatch(actions.schedule.deleteTimeSlot(slot)), [dispatch]);
  const batchDeleteTimeSlots = useCallback((slotIds: number[]) => 
    dispatch(actions.schedule.batchDeleteTimeSlots(slotIds)), [dispatch]);
  const batchCreateTimeSlots = useCallback((timeSlots: MeetingSlot[]) =>
    dispatch(actions.schedule.batchCreateTimeSlots(timeSlots)), [dispatch]);
  const handleMeetingSlotCalendarEventResolution = useCallback((args: MeetingHoldEventErrorResolution) =>
    dispatch(actions.schedule.handleMeetingSlotCalendarEventResolution(args)), [dispatch]);
  const logoutOAuth = useCallback((grant: Grant) => dispatch(actions.auth.logoutOAuth(grant)), [dispatch]);
  const createExternalParticipant = useCallback((data: PrivateExternalParticipantCreate) => {
    return dispatch(actions.schedule.createMeetingParticipant(data));
  }, [dispatch]);

  const removeExternalParticipant = useCallback((
    participantId: number, meetingId: number, isSavedMeeting?: boolean
  ) => {
    return dispatch(actions.schedule.deleteMeetingParticipant(participantId, meetingId, isSavedMeeting));
  }, [dispatch]);

  const setMeetingSlotCalendarEventError = useCallback((args: {
    meetingId: number;
    errors: MeetingHoldEventError[];
  }) => dispatch(actions.schedule.setMeetingSlotCalendarEventError(args)), [dispatch]);
  const duplicateMeeting = useCallback((meetingId: number, data: Partial<Meeting>) => 
    dispatch(actions.schedule.duplicateMeeting(meetingId, data)), [dispatch]);
  const deleteMeeting = useCallback((meeting: Meeting) => 
    dispatch(actions.schedule.deleteMeeting(meeting)), [dispatch]);
  const setNavbarExpanded = useCallback((expanded: boolean) => 
    dispatch(actions.cabUI.setCabNavbarExpanded(expanded)), [dispatch]);


  const params = useParams<RouteParams>();
  const location = useLocation();
  const navigate = router.navigate;

  const currentMeetingId = params.meetingId && meetings[Number(params.meetingId)]
    ? Number(params.meetingId) : undefined;
  const meetingId = currentMeetingId || -1;
  const currentMeeting = useMemo(() => (
    currentMeetingId ? meetings[currentMeetingId] : null
  ), [currentMeetingId, meetings]);
  const currentOrNewMeeting = currentMeeting || newMeeting;

  const [isPoll, setIsPoll] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<MeetingSlot[]>(
    colorDisplayMeetingSlots(meetingSlots, [], meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs)
  );
  const [selectedLeaders, setSelectedLeaders] = useState<number[]>([]);
  const [openAdditionalCalendarsModal, setOpenAdditionalCalendarsModal] = useState(false);
  const [leaderHasAssociations, setLeaderHasAssociations] = useState(true);
  const [currentDateRangeInfo, setCurrentDateRangeInfo] = useState<CalDateRange | null>(null);
  const [displayCalendars, setDisplayCalendars] = useState<DisplayCalendarsDict>({});
  const [calendarTimezoneSelected, setCalendarTimezoneSelected] = useState<TimeZone>(getLocalTimeZone() || NY_TZ);
  const [
    secondaryTimezonesSelected, setSecondaryTimezonesSelected
  ] = useState<Array<TimeZone | undefined>>([getLocalTimeZone()]);
  const topOfViewRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<FormErrors>({meetingName: '', selectedLeader: ''});
  const [allLoaded, setAllLoaded] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingCalendars, setLoadingCalendars] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pollUpdateOpen, setPollUpdateOpen] = useState(false);
  const [invalidatedParticipantVotes, setInvalidatedParticipantVotes] = useState<InvalidParticipantVotes>({});
  const [pendingSlotsToCreate, setPendingSlotsToCreate] = useState<MeetingSlot[]>([]);
  const [pendingSlotsToDelete, setPendingSlotsToDelete] = useState<MeetingSlot[]>([]);
  const [pendingSlotsToUpdate, setPendingSlotsToUpdate] = useState<MeetingSlot[]>([]);
  const [duplicateMeetingModalMeetingId, setDuplicateMeetingModalMeetingId] = useState<number>(-1);
  const [deleteMeetingModalMeetingId, setDeleteMeetingModalMeetingId] = useState<number>(-1);
  const [shareMeetingModalMeetingId, setShareMeetingModalMeetingId] = useState<number>(-1);
  // const [recalculatingSlots, setRecalculatingSlots] = useState<MeetingSlot[]>([]);
  const [updatingSlots, setUpdatingSlots] = useState(false);
  const [showAllRecurringSlots, setShowAllRecurringSlots] = useState(false);
  const [showCanceledDeclinedMeetings, setShowCanceledDeclinedMeetings] = useState(true);
  const [additionalCalendars, setAdditionalCalendars] = useState<NonNullable<Calendar['calendar_access_id']>[]>([]);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const previousMeetingId = usePrevious(currentOrNewMeeting?.id);
  const previousAutoMergeSlots = usePrevious(currentOrNewMeeting?.auto_merge_slots);
  const previousDuration = usePrevious(currentOrNewMeeting?.duration_minutes);

  const calendarMap = useMemo(
    // NOTE WE USE CALENDAR ACCESS ID HEAR BECAUSE THIS IS WHAT WE GET FOR PARTICIPANTS
    // FROM THE BACKEND
    () => Object.fromEntries(calendars
      .filter((calendar): calendar is CalendarWithAccess => !!calendar.calendar_access_id)
      .map(calendar => [calendar.calendar_access_id, calendar])), [calendars]
  );

  const calendarAccessIdtoCalendarPk = useMemo(
    // NOTE WE USE CALENDAR ACCESS ID HEAR BECAUSE THIS IS WHAT WE GET FOR PARTICIPANTS
    // FROM THE BACKEND
    () => Object.fromEntries(calendars
      .filter((calendar): calendar is CalendarWithAccess => !!calendar.calendar_access_id)
      .map(calendar => [calendar.calendar_access_id, calendar.id])), [calendars]
  );

  const additionalCalendarPks = useMemo(() => (
    additionalCalendars.map((accessId) => calendarAccessIdtoCalendarPk[accessId])
      .filter(pk => !!pk)
  ), [additionalCalendars, calendarAccessIdtoCalendarPk]);

  const sortedCalendars = useMemo(() => [...calendars].sort((a, b) => a.id - b.id), [calendars]);

  const calendarFieldsforDep = useMemo(() => sortedCalendars.map((cal) => ({
    id: cal.id,
    calendar_id: cal.calendar_id,
    leaders: cal.leaders,
  })), [sortedCalendars]);

  const calendarDependencyParam = useMemo(() => calendarFieldsforDep.map((cal) => {
    return ([
      cal.id,
      cal.calendar_id,
      cal.leaders
    ].join(";"));
  }).join(",")
  // NOTE: use stringified calendars for hashing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [JSON.stringify(calendarFieldsforDep)]);

  const dateRangeStartDate = useMemo(
    () => currentDateRangeInfo?.start && DateTime.fromJSDate(currentDateRangeInfo.start),
    [currentDateRangeInfo?.start],
  );
  const dateRangeEndDate = useMemo(
    () => currentDateRangeInfo?.end && DateTime.fromJSDate(currentDateRangeInfo.end),
    [currentDateRangeInfo?.end],
  );

  const userRecurringMeetingSlots = useSelector((s: RootState) => (
    selectUserRecurringMeetingSlots(
      s, selectedSlots,
      dateRangeStartDate,
      dateRangeEndDate,
      currentOrNewMeeting?.id
    )
  ));

  const recurringSlots = !showAllRecurringSlots && !currentOrNewMeeting?.id ? [] : userRecurringMeetingSlots;

  const hasGrant = user && checkForCalendarGrants(user.oauth_grant_details);

  const currentMeetingErrors = useMemo(() => (
    currentMeeting?.id ? scheduleErrors[currentMeeting.id] || [] : []
  ), [currentMeeting?.id, scheduleErrors]);

  const currentMeetingSlots = useMemo(
    () => selectedSlots.filter(slot => slot.meeting === meetingId || slot.meeting < 0),
    [selectedSlots, meetingId],
  );

  const activeCalendars = useMemo(() => calendars.filter(assoc => !!displayCalendars[assoc.calendar_id])
    .map(assoc => assoc.id)
  , [calendars, displayCalendars]);

  const hasCalendars = useMemo(() => leaderHasCalendars(
    calendars, selectedLeaders
  ), [calendars, selectedLeaders]);

  const hasAdditionalCalendars = additionalCalendars.length > 0;

  const openMeetingSettings = !!currentOrNewMeeting;

  const leaderMap = useMemo(() => Object.fromEntries<Leader>(
    leaders.leaders.map((leaderItr) => [leaderItr.id, leaderItr])
  ) as {[key: number]:  Leader}, [leaders.leaders]);

  const handleSetSelectedLeaders = useCallback((leaderIds: Leader['id'][]) => {
    if (!isEqual(new Set(leaderIds), new Set(selectedLeaders))) {
      setSelectedLeaders(leaderIds);
    }
  }, [selectedLeaders]);

  useMountEffect(() => {
    return () => {
      deleteNewMeeting();
      setIsPoll(false);
    };
  }); 

  useMountEffect(() => {
    let stop = () => {
      return;
    };
    (async () => {
      stop = await startPollingMeetings({status: MeetingStatus.PENDING});

      // make sure any valid meeting given in the url is loaded, fetch slots if meeting is scheduled
      if (params.meetingId && !meetings[params.meetingId]) {
        await fetchMeeting(params.meetingId);
        await fetchSlotsForMeeting(params.meetingId);
      } else if (params.meetingId && meetings[params.meetingId]?.status !== MeetingStatus.PENDING) {
        await fetchSlotsForMeeting(params.meetingId);
      }
    })();

    return () => stop();
  });

  useMountEffect(() => {
    fetchSchedulingPreferences();
  });

  useMountEffect(() => {
    fetchZoomSettings();

    // If no meeting slots are loaded yet, load only unscheduled
    if (!slotsLoaded) {
      fetchMeetingSlots(true);
    }
  });

  useEffect(() => {
    if (schedulingPrefs.user_prefs?.default_calendar_timezone) {
      const tz = allTimeZones.find(
        (zone: TimeZone) => zone.name === schedulingPrefs.user_prefs?.default_calendar_timezone
      );
      if (tz) {
        setCalendarTimezoneSelected(tz);
      }
    }
  }, [schedulingPrefs.user_prefs?.default_calendar_timezone]);

  useEffect(() => {
    // When someone is not in an active meeting, make sure the calendar timezone defaults are used
    if ((!meetingId || meetingId === -1) && schedulingPrefs.user_prefs?.default_secondary_timezone) {
      setSecondaryTimezonesSelected(schedulingPrefs.user_prefs.default_secondary_timezone.map(defTz => (
        defTz ? allTimeZones.find((zone: TimeZone) => zone.name === defTz) : getLocalTimeZone()
      )));
    }
  // Need to deep-compare timezones
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(schedulingPrefs.user_prefs?.default_secondary_timezone), meetingId]);

  useEffect(() => {
    selectedLeaders.forEach(selectedLeader => {
      if (!leaders.leaders.map(l => l.id).includes(selectedLeader)) {
        const newLeaders = [...selectedLeaders];
        const index = newLeaders.indexOf(selectedLeader);
        if (index > -1) {
          newLeaders.splice(index, 1);
        }
        handleSetSelectedLeaders(newLeaders);
      }
    });
  }, [leaders.leaders, selectedLeaders, handleSetSelectedLeaders]);

  useEffect(() => {
    if (meetingsLoaded && selectedLeaders.length === 0 && leaders.leaders.length > 0
      && (!meetingId || meetingId === -1)
    ) {
      const leadersForScheduling = getLeadersForScheduling(leaders.leaders);
      handleSetSelectedLeaders([leadersForScheduling[0].id]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaders.loaded, selectedLeaders, handleSetSelectedLeaders, meetingId, meetingsLoaded]);

  // this runs when a meeting becomes active or inactive
  useEffect(() => {
    if (currentMeeting) {
      deleteNewMeeting();
      setIsPoll(meetings[meetingId]?.is_poll ?? false);
    }
    topOfViewRef.current?.scrollIntoView({block: 'end', inline: 'nearest'});
  // }, [!!currentMeeting, location.key]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!currentMeeting]);

  useEffect(() => {
    if (meetingId > 0 && meetingsLoaded) {
      if (currentMeeting) {
        const meetingTz = getTimezoneFromMeeting(currentMeeting.calendar_tz);
        if (meetingTz) {
          setCalendarTimezoneSelected(meetingTz); 
        }
        const meetingSecondaryTz = currentMeeting.secondary_tz?.map(tz => (
          getTimezoneFromMeeting(tz)
        )) || [];
        // Meetings might have fewer timezones than the user has enabled in defaults.
        //    If they change their defaults to add timezones, we should show additional timezones when
        //    they open a new meeting. This can happen in the opposite direction (they removed some) but
        //    we don't want to start removing timezones in case they need them for a particular meeting.
        const numPrefTz =  schedulingPrefs.user_prefs?.default_secondary_timezone?.length || 0;
        for (let i = Math.max(0,  numPrefTz - meetingSecondaryTz.length); i > 0; i--) {
          meetingSecondaryTz.push(getLocalTimeZone());
        }
        setSecondaryTimezonesSelected(meetingSecondaryTz);
      } else {
        // The meetings are loaded but this one is not there, so it's probably a scheduled meeting
        //  in this case we will need to load this one on-demand. We also assume if the meeting isn't loaded, the slots
        //  are not either since it's likely a scheduled meeting
        fetchMeeting(meetingId.toString());
        fetchSlotsForMeeting(meetingId.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!currentMeeting, meetingsLoaded, meetingId, fetchMeeting, fetchSlotsForMeeting, 
    schedulingPrefs.user_prefs?.default_secondary_timezone?.length, location.key]);

  useEffect(() => {
    const hasGrantCheck = user && checkForCalendarGrants(user.oauth_grant_details);
    if (hasGrantCheck) {
      setLoadingCalendars(true);
      fetchRemoteCalendars()
        .then(() => {
          setLoadingCalendars(false);
        });
    } else {
      setAllLoaded(true);
    }
  }, [fetchRemoteCalendars, user, user?.oauth_grant_details]); 

  useEffect(() => {
    if (calendarErrors.some(error => error.name === 'tokens_invalid')) {
      const grants = getGrants(auth);
      const providerNames = calendarErrors.map(error => {
        // NOTE: This will log out all usernames for the provider. This will NOT work well when we allow
        //   multiple accounts for each provider to be authenticated simultanously. I.e. one error for one would
        //   log them ALL out.
        grants.forEach(grant => {
          const grantProvider = PROVIDER_BY_NAME[grant.provider];
          if (grantProvider.id === error.provider) {
            logoutOAuth(grant);
          }
        });
        return capitalize(PROVIDER_BY_ID[error.provider].name);
      }).join(', ');
      // TODO: would be good to have a global alert popup we can trigger so this is visible after navigation
      // setAccountManagementMessage('Please re-authenticate with ' + providerNames);
      console.log('Please re-authenticate with ' + providerNames);
      handleOpenAccountManagement();
    } else {
      // setAccountManagementMessage('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarErrors, logoutOAuth]);

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars, user?.oauth_grant_details]);

  // This debounce is because we are updating slots on a few criteria, some of which change simultaneously.
  //   E.g. If Meeting X with Exec A is selected, switching to Meeting Y with Exex B would trigger this
  //   update sequence twice consecutively since both the meeting and exec are changing.
  const updateSelectedSlotsDebounced = useDebouncedCallback(() => {
    if (newMeeting) {
      setSelectedSlots(colorDisplayMeetingSlots([...meetingSlots, ...selectedSlots.filter(s => s.id < 0)],
        selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs));
    } else {
      setSelectedSlots(colorDisplayMeetingSlots(meetingSlots,
        selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs));
    }
  }, 100);

  useEffect(() => {
    if (!updatingSlots && allLoaded) {
      // Combining meetingSlots and selectedSlots is required to prevent losing slots from unsaved meetings      
      updateSelectedSlotsDebounced();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMeetingId, Object.values(meetings).map(m => `${m.id}:${m.status}`).join(','),
    meetingSlots, updatingSlots, allLoaded, selectedLeaders, newMeeting]);

  useEffect(() => {
    if (currentMeeting?.leaders) {
      // make sure we only attempt to set leaders this user has access to
      const leadersToSet = leaders.leaders.filter(leader => currentMeeting?.leaders.includes(leader.id))
        .map(leader => leader.id);
      handleSetSelectedLeaders(leadersToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMeeting?.leaders)]);

  const handleNewMeetingClick = useCallback((poll = false, reusable = false) => {
    clearNavigationState();

    const newMtg = dispatch(actions.schedule.createNewMeeting(
      selectedLeaders,
      additionalCalendars
        .map((calendarAccessId) => calendarMap[calendarAccessId])
        .filter(cal => cal)
        .map((cal, idx) => calendarToParticipant(-1, cal, (idx + 1) * -1)),
      poll,
      reusable,
    ));

    setIsPoll(poll);

    return newMtg;
  }, [additionalCalendars, calendarMap, dispatch, selectedLeaders]);

  const handleCreateOneOffMeeting = useCallback(() => {
    handleNewMeetingClick();
    updateSelectedSlotsDebounced();
  }, [handleNewMeetingClick, updateSelectedSlotsDebounced]);

  const handleCreatePoll = useCallback(() => {
    handleNewMeetingClick(true);
    updateSelectedSlotsDebounced();
  }, [handleNewMeetingClick, updateSelectedSlotsDebounced]);

  const handleCreateReusableMeeting = useCallback(() => {
    handleNewMeetingClick(false, true);
    updateSelectedSlotsDebounced();
  }, [handleNewMeetingClick, updateSelectedSlotsDebounced]);

  useEffect(() => {
    if (location.state?.initSchedule) {
      deleteNewMeeting();
      updateSelectedSlotsDebounced();
    } else if (location.state?.createMeeting) {
      handleCreateOneOffMeeting();
    } else if (location.state?.createMeetingPoll) {
      handleCreatePoll();
    } else if (location.state?.createReusableMeeting) {
      handleCreateReusableMeeting();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, location.state?.initSchedule, location.state?.createMeeting,
    location.state?.createMeetingPoll, location.state?.createReusableMeeting]);

  const getEventFetchData = useCallback((displayCalendarsArg: DisplayCalendarsDict) => {
    const associationsDict: {[key: string]: number[]} = {}; 
    calendars.forEach( association => {
      associationsDict[association.calendar_id] = association.leaders;
    }); 
  
    const requestInfo: EventRequestInfo = {};
    selectedLeaders.forEach(leader => {
      Object.keys(displayCalendarsArg).forEach(calendarId => {
        const isAssociated = (
          selectedLeaders.length > 0 && associationsDict[calendarId] && associationsDict[calendarId].includes(leader)
        );
        if (isAssociated && calendarId) {
          const calendar = calendars.find(calMatch => calMatch.calendar_id === calendarId);
          if (calendar) {
            requestInfo[calendar?.provider] = requestInfo[calendar?.provider] || [];
            requestInfo[calendar?.provider].push(calendar.calendar_id);
          }
        }
      });
    });

    additionalCalendarPks.forEach(cal => {
      Object.keys(displayCalendarsArg).forEach(calendarId => {
        if (cal === displayCalendarsArg[calendarId].id) {
          const calendar = calendars.find(calMatch => calMatch.calendar_id === calendarId);
          if (calendar) {
            requestInfo[calendar?.provider] = requestInfo[calendar?.provider] || [];
            requestInfo[calendar?.provider].push(calendar.calendar_id);
          }
        }
      });
    });

    return requestInfo;
  // We use calendarDependencyParam to detect changes in calendar access changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDependencyParam, JSON.stringify(selectedLeaders), JSON.stringify(additionalCalendars)]);

  const eventsExists = useMemo(() => {
    if (!currentDateRangeInfo?.startStr || !currentDateRangeInfo?.endStr) {
      return false;
    }
    const start = DateTime.fromISO(currentDateRangeInfo.startStr ).toMillis();
    const end = DateTime.fromISO(currentDateRangeInfo.endStr ).toMillis();
    const remoteCalendarIds = Object.values(displayCalendars).map((cal) => cal.calendarId);
    return remoteCalendarIds.map((calendarId) => (
      !!events.find((event) =>  (
        DateTime.fromISO(event.start).toMillis() >= start &&
        DateTime.fromISO(event.end).toMillis() <= end &&
        event.calendarId === calendarId
      ))
    )).some(val => val);
  }, [
    currentDateRangeInfo?.endStr,
    currentDateRangeInfo?.startStr,
    displayCalendars,
    events
  ]);

  const refetchEvents = useCallback(async (
    refetchEventsDisplayCalendars: DisplayCalendarsDict,
    utcStartStr: string, utcEndStr: string, forTimezone: string
  ) => {
    const requestInfo = getEventFetchData(refetchEventsDisplayCalendars);
    
    setLoadingEvents(true);

    await fetchEvents(
      requestInfo[PROVIDER.GOOGLE.id],
      requestInfo[PROVIDER.MICROSOFT.id],
      utcStartStr,
      utcEndStr,
      forTimezone,
      false,
      undefined,
    );

    setLoadingEvents(false);

    // Once the current week's events are fetched, we can unblock the UI and prefetch next week's events
    const start = DateTime.fromISO(utcStartStr);
    const end = DateTime.fromISO(utcEndStr);
    const timeDiff = Math.ceil(end.diff(start, ["days"]).days);
    
    fetchEvents(
      requestInfo[PROVIDER.GOOGLE.id],
      requestInfo[PROVIDER.MICROSOFT.id],
      DateTime.fromISO(utcStartStr).plus({days: timeDiff}).toISO() || "",
      DateTime.fromISO(utcEndStr).plus({days: timeDiff}).toISO() || "",
      forTimezone,
      false,
      undefined,
    );
    
  }, [fetchEvents, getEventFetchData]);

 
  useEffect(() => {
    if ((hasCalendars || hasAdditionalCalendars) && currentDateRangeInfo) {
      const startStr = DateTime.fromISO(currentDateRangeInfo.startStr).toUTC().toString(); 
      const endStr = DateTime.fromISO(currentDateRangeInfo.endStr).toUTC().toString();
      refetchEvents(displayCalendars, startStr, endStr, calendarTimezoneSelected.name); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(Object.keys(displayCalendars)),
    currentDateRangeInfo?.startStr, currentDateRangeInfo?.endStr, refetchEvents,
    hasCalendars, hasAdditionalCalendars,
  ]);

  const leaderCalendarOptions = useMemo(() => (
    calendars.filter(cal => cal.canEdit
      && (selectedLeaders.some(l => cal.leaders.includes(l))
        // make sure user's own calendars are always an option
        || (user?.profile.user_leader && cal.leaders.includes(user?.profile.user_leader))
      ))
  ), [calendars, selectedLeaders, user?.profile.user_leader]);


  const leaderCalendarsToDisplay = useMemo(() => (
    selectedLeaders.filter(lId => currentMeeting
      ? currentMeeting.leader_info?.find(l => l.id === lId)?.view_calendar
      : true)
  ), [currentMeeting, selectedLeaders]);

  const numHiddenCalendarTeammates = useMemo(() => (
    selectedLeaders.length - leaderCalendarsToDisplay.length
  ), [leaderCalendarsToDisplay.length, selectedLeaders.length]);

  useEffect(() => {
    if ((hasCalendars || hasAdditionalCalendars) && user) {
      const additionalCalendarIds = [
        ...additionalCalendarPks,
        ...Object.values(currentMeeting?.participants || {}).filter(p => (
          p.is_fetchable && p.view_calendar && p.calendar_access
        )).map(p => calendarAccessIdtoCalendarPk[p.calendar_access as number])
      ];

      const newCalendarsDisplayed = getCalendarsDisplayed(
        calendars,
        leaderCalendarsToDisplay,
        additionalCalendarIds,
        displayCalendars,
        leaders.leaders
      );
      setDisplayCalendars(newCalendarsDisplayed);  
    }

    setLeaderHasAssociations(Boolean(hasCalendars));
  // ignore displayCalendars leaders.leaders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars, JSON.stringify(additionalCalendars),
    user, hasCalendars, hasAdditionalCalendars, currentMeeting?.leader_info,
    leaderCalendarsToDisplay, currentMeeting?.participants]);


  useEffect(() => {
    let stop = () => {
      return;
    };
    (async () => {
      if ((hasCalendars || hasAdditionalCalendars) && currentDateRangeInfo && user) {
        const newCalendarsDisplayed = getCalendarsDisplayed(calendars, selectedLeaders, additionalCalendarPks,
          displayCalendars, leaders.leaders);
        const startStr = DateTime.fromISO(currentDateRangeInfo?.startStr).toUTC().toString(); 
        const endStr = DateTime.fromISO(currentDateRangeInfo?.endStr).toUTC().toString();
        // TODO: needs to take additional calendars
        const requestInfo = getEventFetchData(newCalendarsDisplayed);

        stop = await startPollEvents(
          requestInfo[PROVIDER.GOOGLE.id],
          requestInfo[PROVIDER.MICROSOFT.id],
          startStr, endStr, calendarTimezoneSelected?.name || "UTC"
        );
      }
    })();
    return () => stop();
    // ignore displayCalendars leaders.leaders getFetchEventData
    // We use calendarDependencyParam to detect changes in calendar access changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDependencyParam, currentDateRangeInfo, additionalCalendarPks, hasAdditionalCalendars,
    startPollEvents, selectedLeaders, user, hasCalendars, calendars
  ]);

  useEffect(() => {
    if (associationsLoaded && calendars.length === 0) {
      navigate(PAGE_URL.MANAGE_CALENDARS);
    }
  }, [associationsLoaded, calendars.length, navigate]);

  useEffect(() => {
    if (associationsLoaded && meetingsLoaded) {
      setAllLoaded(true);
    }
  }, [associationsLoaded, calendarsLoaded, meetingsLoaded]); 

  useEffect(() => {
    setNavbarExpanded(!openMeetingSettings && !isMdDown);
  }, [openMeetingSettings, setNavbarExpanded, isMdDown]);

  useEffect(() => {
    if (!currentOrNewMeeting?.id || !previousMeetingId || previousMeetingId !== currentOrNewMeeting.id) return;
    if (previousAutoMergeSlots != null && currentOrNewMeeting?.auto_merge_slots != null
      && previousAutoMergeSlots !== currentOrNewMeeting?.auto_merge_slots && !currentOrNewMeeting.auto_merge_slots
    ) {
      recalculateSelectedSlots();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrNewMeeting?.auto_merge_slots, previousAutoMergeSlots, currentOrNewMeeting?.id, previousMeetingId]);

  useEffect(() => {
    if (!currentOrNewMeeting?.id || !previousMeetingId || previousMeetingId !== currentOrNewMeeting.id) return;
    if ((currentOrNewMeeting?.duration_minutes || 0) > 0 && previousDuration !== currentOrNewMeeting.duration_minutes
      && currentOrNewMeeting?.auto_merge_slots === false
    ) {
      recalculateSelectedSlots();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrNewMeeting?.duration_minutes, currentOrNewMeeting?.id, previousMeetingId]);

  useEffect(() => {
    if (currentOrNewMeeting?.participants) {
      setAdditionalCalendars(Object.values(currentOrNewMeeting.participants || {}).filter(
        participantItr => !!participantItr.calendar_access && participantItr.view_calendar
      ).map(participantItr => participantItr.calendar_access) as number[]);
    }
  }, [currentOrNewMeeting?.id, currentOrNewMeeting?.participants]);

  const recalculateSelectedSlots = () => {
    if (currentMeetingSlots.length > 0 && currentOrNewMeeting) {
      let newSlotInfo = getSlotsDeleteResult(currentMeetingSlots);
      
      currentMeetingSlots.forEach(slot => {
        const slotInfo: SlotInfo & { id: number } = {
          id: slot.id,
          start: DateTime.fromISO(slot.start_date).toJSDate(),
          end: DateTime.fromISO(slot.end_date).toJSDate(),
          slots: [],
          action: 'select',
        };
        
        // need to pass in the previously created slots during the function when we call this
        const slotCreateResult = getSlotCreateResult(
          slotInfo, slotInfo.id, currentOrNewMeeting, newSlotInfo.createdSlots, slot.is_exclude
        );

        newSlotInfo = [newSlotInfo, slotCreateResult].reduce((a, b) => ({
          createdSlots: [...a.createdSlots, ...b.createdSlots],
          deletedSlots: [...a.deletedSlots, ...b.deletedSlots],
          updatedSlots: [...a.updatedSlots, ...b.updatedSlots],
        }));

      });

      attemptSlotUpdates(newSlotInfo);
    }
  };

  const updateSlotNames = useCallback((newMeetingName: string) => {
    setSelectedSlots(selectedSlots.map(slot => ({
      ...slot,
      title: slot.meeting === currentMeetingId ? newMeetingName : slot.title,
    })));
  // NOTE: just for selectedSlots
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMeetingId, JSON.stringify(selectedSlots)]);

  const updateSlots = useCallback(async ({createdSlots, updatedSlots, deletedSlots}: SlotState) => {
    setUpdatingSlots(true);

    const slotsToDelete = deletedSlots.filter(slot => slot.id > 0);
    if (slotsToDelete.length === 1) {
      slotOperationQueue.push({ slot: slotsToDelete[0], operationFn: deleteTimeSlot });
    } else if (slotsToDelete.length > 1) {
      slotOperationQueue.push({ slot: slotsToDelete.map(slot => slot.id), operationFn: batchDeleteTimeSlots });
    }

    const slotsToUpdate = updatedSlots.filter(slot => slot.id > 0);
    slotsToUpdate.forEach(slot => {
      slotOperationQueue.push({ slot, operationFn: updateTimeSlot });
    });

    if (meetingId > 0 && createdSlots.length > 0) {
      slotOperationQueue.push({ slot: createdSlots, operationFn: batchCreateTimeSlots });
    }

    const nextSlots = selectedSlots
      .map(existingEvent => updatedSlots.find(updateSlot => updateSlot.id === existingEvent.id) || existingEvent)
      .filter(existingEvent => !deletedSlots.find(removeSlot => removeSlot.id === existingEvent.id))
      .concat(createdSlots);
    const coloredDisplayMeetingSlots = colorDisplayMeetingSlots(
      nextSlots, selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs
    );
    setSelectedSlots(coloredDisplayMeetingSlots);

    // TODO: there may still be an issue where this hangs... seems to happen when multiple queue pushes
    // happen above
    await slotOperationQueue.drained();

    setUpdatingSlots(false);
  }, [batchCreateTimeSlots, batchDeleteTimeSlots, currentMeetingId,
    deleteTimeSlot, meetingId, meetings, schedulingPrefs.user_prefs, selectedLeaders, selectedSlots, updateTimeSlot]);

  const validateSlotChanges = useCallback(({
    paramMeetingId, createdSlots, updatedSlots, deletedSlots, existingSlots
  }: {
    paramMeetingId: number, createdSlots: MeetingSlot[], updatedSlots: MeetingSlot[],
    deletedSlots: MeetingSlot[], existingSlots: MeetingSlot[]
  }) => {
    const meetingParam = meetings[paramMeetingId];

    if (meetingParam && meetingParam.is_poll) {
      const invalidVotesFound = getInvalidParticipantVotes(
        meetingParam, createdSlots, deletedSlots, updatedSlots, existingSlots
      );
      
      if (Object.keys(invalidVotesFound).length > 0) {
        setInvalidatedParticipantVotes(invalidVotesFound);
        setPendingSlotsToCreate(createdSlots);
        setPendingSlotsToDelete(deletedSlots);
        setPendingSlotsToUpdate(updatedSlots);
        setPollUpdateOpen(true);
      } else {
        return updateSlots({ createdSlots, deletedSlots, updatedSlots });
      }
    } else {
      return updateSlots({ createdSlots, deletedSlots, updatedSlots });
    }
    return Promise.resolve();
  }, [meetings, updateSlots]);

  const attemptSlotUpdates = useCallback((slotState: SlotState) => {
    return validateSlotChanges({
      ...slotState,
      existingSlots: selectedSlots,
      paramMeetingId: meetingId,
    });
  }, [meetingId, selectedSlots, validateSlotChanges]);

  const handleOpenAccountManagement = () => {
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  const handleOpenMeeting = useCallback((handleOpenMeetingId: number): void => {
    setIsPoll(meetings[handleOpenMeetingId]?.is_poll ?? false);
    navigate(`${PAGE_URL.SCHEDULE}/${handleOpenMeetingId}/`);
  }, [navigate, meetings]);

  const handleRemoveLeader = useCallback((mtgId: number, leaderId: number) => {
    const meetingLeader = currentOrNewMeeting?.leader_info?.find(mtgLeader => mtgLeader.id === leaderId);
    if (!meetingLeader) return;

    handleSetSelectedLeaders(selectedLeaders.filter(lId => lId !== meetingLeader.id));
  }, [currentOrNewMeeting?.leader_info, handleSetSelectedLeaders, selectedLeaders]);

  const handleAddLeader = useCallback((currentMtgId: number, id: number) => {
    if (!selectedLeaders.includes(id)) {
      handleSetSelectedLeaders([...selectedLeaders, id]);
    }
  }, [handleSetSelectedLeaders, selectedLeaders]);

  const handleLeaderSelect = (ids: number[]): void => {
    if (errors.selectedLeader) {
      setErrors(prev => ({...prev, selectedLeader: ''}));
    }

    handleSetSelectedLeaders(ids);
  };

  const handleCancel = useCallback(() => {
    if (currentMeetingId) {
      navigate(PAGE_URL.SCHEDULE);
    } else {
      deleteNewMeeting();
    }
    setSelectedSlots(clearSelectedSlots(selectedSlots));
    clearNavigationState();
  }, [deleteNewMeeting, selectedSlots, currentMeetingId, navigate]);

  const handleCalendarClick = useCallback((calendarId: string) => {
    setDisplayCalendars(prev => ({...prev, [calendarId]: { 
      ...prev[calendarId],
      display: !prev[calendarId].display} 
    }));
  }, []);
  
  const handleCalendarTimezoneSelected = useCallback((timezone: TimeZone) => {
    setCalendarTimezoneSelected(timezone);
    if (currentMeetingId) {
      updateMeeting({ id: currentMeetingId, calendar_tz: timezone?.name || null}, []);
    }
  }, [currentMeetingId, updateMeeting]);

  const handleSecondaryTimezoneSelected = useCallback((idx: number, timezone: TimeZone) => {
    const newTimezones = [
      ...secondaryTimezonesSelected.slice(0, idx), timezone, ...secondaryTimezonesSelected.slice(idx + 1)
    ];
    setSecondaryTimezonesSelected(newTimezones);
    const newTimezoneNames = newTimezones.map(tz => tz?.name || null);
    if (currentMeetingId) {
      updateMeeting({ id: currentMeetingId, secondary_tz: newTimezoneNames}, []);
    }
  }, [currentMeetingId, secondaryTimezonesSelected, updateMeeting]);

  const handleResolution = (context: MeetingHoldEventErrorResolution) => {
    handleMeetingSlotCalendarEventResolution(context).then(res => {
      if (res) {
        if (meetingId > 0) {
          const schedErrors = scheduleErrors[meetingId].filter(
            error => error.meetingSlotCalendarEventId !== context.meetingSlotCalendarEventId
          );
          setMeetingSlotCalendarEventError(
            {
              errors: schedErrors,
              meetingId: meetingId
            }
          );
          if (schedErrors.length === 0) {
            setErrorModalOpen(false);
          }
        }
      }
    });
  };

  const handleDuplicateMeeting = useCallback((handleDuplicateMeetingId: number) => {
    setDuplicateMeetingModalMeetingId(handleDuplicateMeetingId);
  }, []);

  const handleSubmitDuplicateMeeting = async (data: DuplicateMeetingSubmitProps) => {
    const meeting = await duplicateMeeting(duplicateMeetingModalMeetingId, data);
    if (meeting) {
      navigate(`${PAGE_URL.SCHEDULE}/${meeting.id}/`);
      setDuplicateMeetingModalMeetingId(-1);
      setShareMeetingModalMeetingId(meeting.id);
      return meeting;
    }
    return undefined;
  };

  const handleDeleteMeeting = useCallback((handleDeleteMeetingId: number) => {
    setDeleteMeetingModalMeetingId(handleDeleteMeetingId);
  }, []);

  const handleSubmitDeleteMeeting = async (meeting: Meeting) => {
    await deleteMeeting(meeting);
    if (meeting.id === currentMeetingId) {
      navigate(PAGE_URL.SCHEDULE);
    }
    setDeleteMeetingModalMeetingId(-1);
  };

  const handleShareMeeting = useCallback((handleShareMeetingMeetingId: number) => {
    setShareMeetingModalMeetingId(handleShareMeetingMeetingId);
  }, []);

  const handleShareCurrentMeeting = useCallback(() => {
    handleShareMeeting(currentMeetingId || -1);
  }, [handleShareMeeting, currentMeetingId]);

  const handlePollUpdateAccept = useCallback(() => {
    updateSlots({
      createdSlots: pendingSlotsToCreate,
      deletedSlots: pendingSlotsToDelete,
      updatedSlots: pendingSlotsToUpdate
    });
    setInvalidatedParticipantVotes({});
    setPollUpdateOpen(false);
  }, [pendingSlotsToCreate, pendingSlotsToDelete, pendingSlotsToUpdate, updateSlots]);

  const handlePollUpdateReject = useCallback(() => {
    setPendingSlotsToCreate([]);
    setPendingSlotsToDelete([]);
    setPendingSlotsToUpdate([]);
    setInvalidatedParticipantVotes({});
    setPollUpdateOpen(false);
  }, []);

  const handleCreateSlot = useCallback((info: SlotInfo) => {
    // make sure we handle first slot selected when meeting panel is closed
    const mtg = currentOrNewMeeting || handleNewMeetingClick();
    // if an id is given, use it (useful for batch/re-creation). Otherwise generate one.
    const newSlotId = getMinSlotId(selectedSlots) - 1;
    attemptSlotUpdates(getSlotCreateResult(info, newSlotId, mtg, currentMeetingSlots, false));
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting, handleNewMeetingClick, selectedSlots]);

  const handleCreateExcludeSlots = useCallback((slots: ExcludedSlotInfo[]) => {
    let newSlotId = getMinSlotId(selectedSlots) - 1;
    const mtg = currentOrNewMeeting || handleNewMeetingClick();
    // TODO: This is not an optimal strategy, we should not need to create a new 
    // variable to store slotState, but selectedSlot state is not updating between cycles.
    let slotState: SlotState = {createdSlots: [], deletedSlots: [], updatedSlots: []};
    slots.forEach(slot => {
      const slotCreateResult = getSlotCreateResult(slot, newSlotId, mtg, currentMeetingSlots, true);
      slotState = {
        createdSlots:[...slotState.createdSlots, ...slotCreateResult.createdSlots], 
        deletedSlots: [...slotState.deletedSlots, ...slotCreateResult.deletedSlots], 
        updatedSlots:[...slotState.updatedSlots, ...slotCreateResult.updatedSlots]
      };
      newSlotId--;
    });
    attemptSlotUpdates(slotState);
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting, handleNewMeetingClick, selectedSlots]);

  const handleEditSlot = useCallback((eventId: string, start: Date, end: Date, isExcluded: boolean) => {
    attemptSlotUpdates(getSlotEditResult(
      eventId, start, end, currentMeetingSlots, isExcluded, currentOrNewMeeting || undefined
    ));
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting]);

  const handleDeleteSlots = useCallback((slots: MeetingSlot[]) => {
    attemptSlotUpdates(getSlotsDeleteResult(slots));
  }, [attemptSlotUpdates]);

  const handleSaveRecurringTimes = useCallback((recurringTimes?: RecurringTimes) => {
    if (currentMeetingId) {
      updateMeeting({ id: currentMeetingId, recurring_times: recurringTimes || null}, []);
    } else {
      updateNewMeeting({ recurring_times: recurringTimes || undefined});
    }
  }, [currentMeetingId, updateMeeting, updateNewMeeting]);

  const handleToggleShowAllRecurringSlots = useCallback(() => {
    setShowAllRecurringSlots(!showAllRecurringSlots);
  }, [setShowAllRecurringSlots, showAllRecurringSlots]);

  const handleToggleCanceledDeclinedMeetings = useCallback(() => {
    setShowCanceledDeclinedMeetings(!showCanceledDeclinedMeetings);
  }, [setShowCanceledDeclinedMeetings, showCanceledDeclinedMeetings]);

  const handleConvertToOneOff = async (handleConvertToOneOffMeetingId: number) => {
    await updateMeeting({ id: handleConvertToOneOffMeetingId, use_template_parent: false }, []);
  };

  const handleUpdateAdditionalCalendars = useCallback(async (newAdditionalCalendarAccessIds: number[]) => {
    if (currentOrNewMeeting?.id != null) {
      const participants = Object.fromEntries(
        newAdditionalCalendarAccessIds
          .map((calendarAccessPk) => calendarMap[calendarAccessPk])
          .filter(cal => cal)
          .map((cal, idx) => {
            const assignedId = (idx + 1) * -1;
            return [assignedId, calendarToParticipant(currentOrNewMeeting?.id || -1, cal, assignedId)];
          })
      );

      if (currentOrNewMeeting?.id < 0) {
        const nonCalendarParticipants = Object.fromEntries(Object.values(currentOrNewMeeting.participants || {}).filter(
          partItr => partItr.calendar_access === null
        ).map(
          (partItr) => [partItr.id, partItr]
        ));
        updateNewMeeting({
          ...currentOrNewMeeting,
          participants: {...nonCalendarParticipants, ...participants}
        });
      } else {
        let createPromises: Promise<void>[] = [];
        const attachedCalendars = Object.values(currentOrNewMeeting.participants || {}).filter(
          part => !!part.calendar_access).map(
          participant => participant.calendar_access
        );
        const newCalendars = Object.values(participants).filter(
          participant => !attachedCalendars.includes(participant.calendar_access)
        );
        const removeCalendars = difference(attachedCalendars, newAdditionalCalendarAccessIds);
        
        newCalendars.forEach(async (participant) => {
          createPromises.push(createExternalParticipant(participant));
          if (createPromises.length === 5) {
            await Promise.all(createPromises);
            createPromises = [];
          }
        });
        await Promise.all(createPromises);

        const removePromises: Promise<void>[] = [];

        const removeIds = Object.values(currentOrNewMeeting.participants || {}).filter(participant => {
          return removeCalendars.includes(participant.calendar_access);
        }).map(participant => participant.id);
        removeIds.forEach(async (removeId) => {
          removePromises.push(
            removeExternalParticipant(removeId, currentOrNewMeeting.id || -1, !!currentOrNewMeeting.id)
          );
          if (removePromises.length === 5) {
            await Promise.all(removePromises);
          }
        });
        await Promise.all(removePromises);
      }
    }
    setAdditionalCalendars(newAdditionalCalendarAccessIds);
    setOpenAdditionalCalendarsModal(false);
  }, [calendarMap, createExternalParticipant, currentOrNewMeeting, removeExternalParticipant, updateNewMeeting]);

  const handleOpenAdditionalCalendarsModal = useCallback(() => (
    setOpenAdditionalCalendarsModal(true)
  ), [setOpenAdditionalCalendarsModal]);

  return (
    <CabinetPage
      pageName={'Schedule'}
      headerBackgroundColor={colors.white900}
      headerContent={<>
        <CabinetModal
          open={currentMeetingErrors.length > 0 ? errorModalOpen : false}
          component={
            <MeetingErrors
              meeting={currentMeeting}
              meetingErrors={currentMeetingErrors}
              handleResolution={handleResolution}
              calendars={calendars}
            />
          }
          onClose={() => setErrorModalOpen(false)}         
        />
        <ScheduleHeaderContainer
          selectedLeaders={selectedLeaders}
          onLeaderSelect={handleLeaderSelect}
          creatingMeeting={!!newMeeting}
          onCancel={handleCancel}
          currentMeeting={currentMeeting}
          opencalendarsModal={() => navigate(PAGE_URL.MANAGE_CALENDARS)}
          onCreateMeeting={handleCreateOneOffMeeting}
          onCreatePoll={handleCreatePoll}
          onCreateReusableMeeting={handleCreateReusableMeeting}
        />
      </>}
    >
      {((!eventsExists && loadingEvents) || !allLoaded) && (
        <Box sx={{
          position: 'absolute',
          zIndex: 200,
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CabSpinner scale={4} color='inherit'/>
        </Box>
      ) }
      {allLoaded && (
        <Box display="flex" flex={1} height="100%">
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            // for open side panel offset
            marginRight={openMeetingSettings ? 0 : `-${CAB_PANEL_WIDTH}px`}
          >
            <CalendarSchedulerContainer
              selectedLeaders={selectedLeaders}
              leaderHasAssociations={leaderHasAssociations}
              userHasGrant={Boolean(hasGrant)}
              currentMeetingId={currentMeetingId}
              displayCalendars={displayCalendars}
              // This should be refactored at some point to use calendar accesses
              additionalCalendarIds={additionalCalendarPks}
              onUpdateAdditionalCalendar={handleUpdateAdditionalCalendars}
              loadingCalendars={loadingCalendars}
              onCalendarClick={handleCalendarClick}
              openAdditionalCalendarsModal={handleOpenAdditionalCalendarsModal}
              handleCalendarTimezoneSelected={handleCalendarTimezoneSelected}
              handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
              currentDateRangeInfo={currentDateRangeInfo}
              onDateChange={setCurrentDateRangeInfo}
              //Scheduler
              calendarTimezoneSelected={calendarTimezoneSelected}
              secondaryTimezonesSelected={secondaryTimezonesSelected}
              handleOpenMeeting={handleOpenMeeting}
              selectedSlots={selectedSlots}
              recurringSlots={recurringSlots}
              showAllRecurringSlots={showAllRecurringSlots}
              onToggleShowAllRecurringSlots={handleToggleShowAllRecurringSlots}
              showCanceledDeclinedMeetings={showCanceledDeclinedMeetings}
              onToggleCanceledDeclinedMeetings={handleToggleCanceledDeclinedMeetings}
              handleSlotsCreated={handleCreateSlot}
              handleEditSlot={handleEditSlot}
              handleDeleteSlots={handleDeleteSlots}
              isPoll={isPoll}
              handleDuplicateMeeting={handleDuplicateMeeting}
              handleDeleteMeeting={handleDeleteMeeting}
              handleShareMeeting={handleShareMeeting}
              numHiddenCalendarTeammates={numHiddenCalendarTeammates}
              sx={calendarSchedulerSx}
            />
          </Box>
          {currentMeeting?.use_template_parent && currentMeeting.template_parent ? (
            <ReusableMeetingModal
              open={!!currentMeeting}
              meeting={currentMeeting}
              onClose={handleCancel}
              onConvertToOneOff={() => handleConvertToOneOff(currentMeeting.id)}
              onEditReusable={() => currentMeeting.template_parent && handleOpenMeeting(currentMeeting.template_parent)}
            />
          ) : (
            <MeetingSettingsContainer
              leaderMap={leaderMap}
              currentMeetingId={currentMeetingId}
              meetingSlots={currentMeetingSlots}
              onUpdateMeetingName={updateSlotNames}
              selectedLeaders={selectedLeaders}
              activeCalendars={activeCalendars}
              calendarTimezone={calendarTimezoneSelected}
              secondaryTimezones={secondaryTimezonesSelected}
              onDeleteSlots={handleDeleteSlots}
              onRemoveLeader={handleRemoveLeader}
              onAddLeader={handleAddLeader}
              onSetSelectedLeaders={setSelectedLeaders}
              onShowErrors={setErrorModalOpen}
              meetingErrors={currentMeetingErrors}
              onShare={handleShareCurrentMeeting}
              onCancel={handleCancel}
              openMeetingSettings={openMeetingSettings}
              slotsAreRecalculating={updatingSlots}
              pollUpdateOpen={pollUpdateOpen}
              onPollUpdateAccept={handlePollUpdateAccept}
              onPollUpdateReject={handlePollUpdateReject}
              invalidMeetingSlots={invalidatedParticipantVotes}
              onSaveRecurringTimes={handleSaveRecurringTimes}
              onExcludedSlotsCreated={handleCreateExcludeSlots}
              onEditSlot={handleEditSlot}
              additionalCalendars={additionalCalendarPks}
              leaderCalendarOptions={leaderCalendarOptions}
              onCalendarTimezoneSelected={handleCalendarTimezoneSelected}
            />
          )}
          <ScheduleShareModalContainer
            key={shareMeetingModalMeetingId}
            meetingId={shareMeetingModalMeetingId}
            onModalClose={() => setShareMeetingModalMeetingId(-1)}
            modalOpen={shareMeetingModalMeetingId > 0}
            showEdit
          />
          <DuplicateMeetingModal
            open={duplicateMeetingModalMeetingId > 0}
            onClose={() => setDuplicateMeetingModalMeetingId(-1)}
            meeting={meetings[duplicateMeetingModalMeetingId]}
            submitDuplicate={handleSubmitDuplicateMeeting}
          />
          <DeleteMeetingModal
            open={deleteMeetingModalMeetingId > 0}
            onClose={() => setDeleteMeetingModalMeetingId(-1)}
            meeting={meetings[deleteMeetingModalMeetingId]}
            submitDelete={handleSubmitDeleteMeeting}
          />
          {openAdditionalCalendarsModal && (
            <AdditionalCalendars
              isOpen={openAdditionalCalendarsModal} 
              onDone={handleUpdateAdditionalCalendars}
              onCancel={() => setOpenAdditionalCalendarsModal(false)}
              selectedCalendars={additionalCalendars}
              selectedLeaders={selectedLeaders}
              onSelectLeaders={handleLeaderSelect}
            />
          )}
        </Box>
      )}
    </CabinetPage>
  );
};

export default Schedule;